import VueSweetalert2 from 'vue-sweetalert2'

export default {
  install(Vue) {
    Vue.use(VueSweetalert2)
    /* eslint-disable no-param-reassign */
    Vue.prototype.$modal = {
      show({
        title, text, type, showCancelButton, cancelButtonText, confirmButtonText, width,
      }) {
        return Vue.prototype.$swal({
          title,
          text,
          icon: type || 'warning',
          showCancelButton: showCancelButton || true,
          customClass: {
            title: 'font-medium-3 font-family-base font-weight-normal text-dark-gray',
            htmlContainer: 'font-small-3 font-weight-light',
            confirmButton: 'btn btn-primary rounded-sm p-1',
            cancelButton: 'btn btn-outline-danger ml-1 rounded-sm p-1',
            actions: 'my-2',
          },
          width: width || '30em',
          confirmButtonText: confirmButtonText || 'Aceptar',
          cancelButtonText: cancelButtonText || 'Cancelar',
          buttonsStyling: false,
        })
      },
    }
  },
}
